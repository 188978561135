import React,{useEffect} from "react";
import './Visualization.css';
import "@fontsource/red-hat-display";
import './../min.css';
import decisionBoard from "./resources/decision-board.svg";
import connect from "./resources/connect.png"
import prepare from "./resources/prepare.jpeg"
import automate from "./resources/automate.png"
import explore from "./resources/explore.jpeg"
import collaborate from "./resources/collaborate.png"

function Dashboard(){
    var coll = document.getElementsByClassName("dashboard-ul");
    var dashboards=document.getElementsByClassName('dashboard_display');

    
    
    useEffect(()=>{
        var i;
        for (i = 0; i < coll.length; i++) {
            const clss='class'+i;
            coll[i].addEventListener("click",function(event,cls=clss) {
                var j;
                for (j = 0; j < coll.length; j++) {
                    coll[j].classList.remove('underline');
                    // alert((dashboards[j]).style);
                    // dashboards[j].style.width='0px'
                    // alert('class'+j);
                    document.getElementsByClassName('class'+j)[0].style.display="none";
                    // dashboards[j].classList.remove('display');
                }
                // dashboards[i].style.display="block";
                this.classList.add("underline");
                document.getElementsByClassName(cls)[0].style.display="block";
                // dashboards[i].style.width='80%'
// =                dashboards[i].classList.add('display');
                    
            //   this.getElementsByClassName("expand")[0].classList.toggle("collapse");
            //   var content = this.nextElementSibling;
            //   if (content.style.maxHeight){
            //     content.style.maxHeight = null;
            //   } else {
            //     content.style.maxHeight = content.scrollHeight + "px";
            //   }
            });
            
          }
    })
    return(
        // <div className="body-deicison">
            
            
        //     <div className="logo-heading">
        //         <img src={visualizationBackground} style={{width:'100%',height:'300px'}}/>
        //     </div>
        //     <div>
        //         <div className="heading-text-h2" style={{"margin-bottom":"20px"}}>Visualization and</div>
        //         <div className="heading-text-h2" style={{"margin-bottom":"20px"}}>Data Intelligence</div>
        //         <div className="para-text-h3" style={{"font-size":"1.5vw",'text-align':'left'}}>Lorem Ipsum is simply dummy text of the printing <br/>and  typesetting industry. Lorem Ipsum has been <br/> the industry's standard dummy text ever since the 1500</div>
        //     </div>
        //     </div>
<>    
<div className="heading-text-h2" style={{'padding-bottom':'20px','text-align':'center'}}>What You Get with Dashboard</div>

<div className="body-dashboard" style={{'margin-bottom':'100px'}}>    
<div className="dashboard-nav" >
        <p className="dashboard-ul underline">Connect</p>
        <p className="dashboard-ul">Prep</p>
        <p className="dashboard-ul">Automate</p>
        <p className="dashboard-ul">Explore</p>
        <p className="dashboard-ul">Visualize</p>
        <p className="dashboard-ul">Collaborate</p>

</div>
<div className="dashboard_display class0" style={{width:'80%','text-align':'left','padding':'50px'}}>
    <img src={connect} style={{width:'100%','max-width':'600px'}}/>
    <div className="heading-text-h3" style={{"margin-bottom":"20px"}}>Connect to the right data source</div>
    <div className="para-text-h3"> Connecting to right data source is foremost step to get  <br/>your business in right direction We follow the the industry's<br/>  standard process to get your data.</div>
</div>
<div className="dashboard_display class1" style={{width:'80%','text-align':'left','padding':'50px','display':'none'}}>
    <img src={prepare} style={{width:'100%','max-width':'600px'}}/>
    <div className="heading-text-h3" style={{"margin-bottom":"20px"}}>Prepare your data</div>
    <div className="para-text-h3">Data extracted are unprocessed and uncleaned. <br/>To get a valuable information, data cleaning  <br/> and preparing data becomes critical step towards the process.</div>
</div>
<div className="dashboard_display class2" style={{width:'80%','text-align':'left','padding':'50px','display':'none'}}>
    <img src={automate} style={{width:'100%','max-width':'600px'}}/>
    <div className="heading-text-h3" style={{"margin-bottom":"20px"}}>Automate the process</div>
    <div className="para-text-h3">Automate the process of connecting to the right data source <br/>and preparing them to visualize your business driven information. <br/> Avoid the steps involved which helps reduce efforts and error by automating them</div>
</div>
<div className="dashboard_display class3" style={{width:'80%','text-align':'left','padding':'50px','display':'none'}}>
    <img src={explore} style={{width:'100%','max-width':'600px'}}/>
    <div className="heading-text-h3" style={{"margin-bottom":"20px"}}>Exploration of data is where your decision begins</div>
    <div className="para-text-h3">Selecting the right metrics, data points and information is really important.<br/> Our experts using their years of expereince will pick the right  points   <br/> and metrics from your data which is important for your business.</div>
</div>
<div className="dashboard_display class4" style={{width:'80%','text-align':'left','padding':'50px','display':'none'}}>
    <img src={decisionBoard} style={{width:'100%','max-width':'600px'}}/>
    <div className="heading-text-h3" style={{"margin-bottom":"20px"}}>Get your dashboard</div>
    <div className="para-text-h3">Get your clear, concise and easy to understand data. <br/>Visualization allows business users to identify relationships,<br/> patterns, KPI's for decision making.</div>
</div>
<div className="dashboard_display class5" style={{width:'80%','text-align':'left','padding':'50px','display':'none'}}>
    <img src={collaborate} style={{width:'100%','max-width':'600px'}}/>
    <div className="heading-text-h3" style={{"margin-bottom":"20px"}}>Collaborate your dashboard with your team, customers and clients</div>
    <div className="para-text-h3">Collaborate your dashboard, this helps decision making process easier<br/> by sharing the metrics, KPI's and other useful information with the team,<br/> your customers or decision makers</div>
</div>
</div>
</>

            
    );


}
export default Dashboard;