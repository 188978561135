import React,{useEffect,useState,useRef} from "react";
import './Governance.css';
import "@fontsource/red-hat-display";
import './../min.css';
import secuirty from './resources/security.svg'
import {Link} from 'react-router-dom'


function Display(){
    const [slideIndex, setSlideIndex] = useState(0);
    const [slideCount,setSlideCount]=useState(0);

    const [n,setN]=useState(1);
    const stateRef = useRef();  
    stateRef.current=slideIndex;
      useEffect(()=>{
        var i;   
        var x = document.getElementsByClassName("governance-slides");
        var y = document.getElementsByClassName("tilt-90-button");

        for (i = 0; i < x.length; i++) {
            x[i].style.display = "none";  
            y[i].classList.remove("button-active")
        }
        x[slideIndex].className="governance-slides fade-in";
        y[slideIndex].classList.add("button-active")
        x[slideIndex].style.display = "inline";
        setSlideCount(document.getElementsByClassName("governance-slides").length);

      },[slideIndex]);

    return(
        <div className="body-governance">
            
            <div className="tilt-90-button" style={{background: '#E87A00'}} onClick={()=>setSlideIndex(0)}>
            <div style={{'padding-top':'10px'}}>
                <h2>Governance</h2>
                </div>
                
                <svg style={{'align':'center'}} width="67" height="67" viewBox="0 0 67 67" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="33.5" cy="33.5" r="32.5" stroke="black" stroke-width="2"/>
                    <path d="M56.0607 35.0607C56.6464 34.4749 56.6464 33.5251 56.0607 32.9393L46.5147 23.3934C45.9289 22.8076 44.9792 22.8076 44.3934 23.3934C43.8076 23.9792 43.8076 24.9289 44.3934 25.5147L52.8787 34L44.3934 42.4853C43.8076 43.0711 43.8076 44.0208 44.3934 44.6066C44.9792 45.1924 45.9289 45.1924 46.5147 44.6066L56.0607 35.0607ZM12 35.5H55V32.5H12V35.5Z" fill="black"/>
                </svg>
            </div>
            
            <div className="tilt-90-button" style={{background: '#7AB6E4'}} onClick={()=>setSlideIndex(1)}>
            <div style={{'padding-top':'10px'}}>
                                    <h2>Security</h2>
            </div>
                 
                   
                <svg width="67" height="67" viewBox="0 0 67 67" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="33.5" cy="33.5" r="32.5" stroke="black" stroke-width="2"/>
                    <path d="M56.0607 35.0607C56.6464 34.4749 56.6464 33.5251 56.0607 32.9393L46.5147 23.3934C45.9289 22.8076 44.9792 22.8076 44.3934 23.3934C43.8076 23.9792 43.8076 24.9289 44.3934 25.5147L52.8787 34L44.3934 42.4853C43.8076 43.0711 43.8076 44.0208 44.3934 44.6066C44.9792 45.1924 45.9289 45.1924 46.5147 44.6066L56.0607 35.0607ZM12 35.5H55V32.5H12V35.5Z" fill="black"/>
                </svg>
                </div>
                <div className="display">
                    <div className="governance-slides">
                    <img src={secuirty} className="security-display"/>
                        <div className="heading-text-h2" style={{"margin-left":"40px"}}>
                            Govern your organization
                            <p className="para-text-h3">Want to secure your enterprise’s Operation's initiatives? Integrate continuous security & monitoring features into your everyday processes now! </p>
                            <div style={{"display":"inline-flex","width":"100%"}}>
                            <div className="service-button" style={{"height":"100%","margin":"auto","color":"#fff"}}><a href="contact">Get Started</a></div>
                            <div className="service-button" style={{"height":"100%","margin":"auto","background":"none","color":"#000"}}><Link to="../contact">Get Started</Link></div>

                            </div>
                    </div>
                    </div>
                <div className="governance-slides">
                    <img src={secuirty} className="security-display"/>
                    <div className="heading-text-h2" style={{"margin-left":"40px"}}>
                        Secuirty is our Priority
                        <p className="para-text-h3">Our setup will guarantee the security of your firm & its customers. Our experts will ensure that your robust security setup consistently matches the speed of continuous delivery & integration. </p>
                        <div style={{"display":"inline-flex","width":"100%"}}>
                        <div className="service-button" style={{"height":"100%","margin":"auto","color":"#fff"}}><a href="contact">Get Started</a></div>
                        <div className="service-button" style={{"height":"100%","margin":"auto","background":"none","color":"#000"}}><Link to="../contact">Get Started</Link></div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );


}
export default Display;