import React from "react";
import './SoftwareDevelopment.css';
import "@fontsource/red-hat-display";
import Display from "./Display";
import ArchitectureDesign from "./ArchitectureDesign";
import DevOps from './DevOps';
import TestCode from './TestCode';
import Engineer from "./Engineer";
function Prototyping(){
    return(
        <div>
        <Display/>
        <ArchitectureDesign/>
        <DevOps/>
        <TestCode/>
        {/* <Engineer/> */}
        </div>
    );


}
export default Prototyping;