import React from "react";
import './Prototyping.css';
import "@fontsource/red-hat-display";
import Display from "./Display";
import OurService from './Services';
import Works from './Works';
import Tools from './Tools';
function Prototyping(){
    return(
        <div>
        <Display/>
        <OurService/>
        <Works/>
        <Tools/>
        </div>
    );


}
export default Prototyping;