import React from "react";
import './Prototyping.css';
import "@fontsource/red-hat-display";

import ui_ux_design from './resources/ui-ux-design.svg';
import ux_audit from './resources/ux-audit.svg';

import './../min.css';
function OurService(){
    return(
        <div className="body-prototype-service">
            <h1 style={{'text-align':'center','color':'white'}}>Our Service</h1>
            <div className="row">
                <div className="box-prototyping"><img src={ui_ux_design} style={{width:'75px'}}/><p className="service-specific-text-prototyping">UI & UX<br/> Design</p><p> We’ll help you create & implement well-thought-out UI/UX design  strategies that align with your short & long-term business goals.</p></div>
                <div className="box-prototyping"><img src={ux_audit} style={{width:'75px'}}/><p className="service-specific-text-prototypingt">UX Audit</p><p>These audits reveal the most pressing UX issues in our customers’ products.  </p></div>
                <div className="box-prototyping"><img src={ui_ux_design} style={{width:'75px'}}/><p className="service-specific-text-prototyping">Brand Identity Design</p><p>Build a distinct, purpose-driven, & customer-oriented visual identity for your brand with the assistance of our design experts. </p></div>
           </div>
        </div>

    );
}
export default OurService;