import React from "react";
import './Visualization.css';
import "@fontsource/red-hat-display";
import './../min.css';
import visualizationBackground from "./resources/display-background.svg";
function Display(){
    return(
        <div className="body-visulization ">
            
            <div className="div-40 div-mob">
                <div className="heading-text-h2" style={{"margin-bottom":"20px","font-size":"4vw",}}>Visualization and Data Intelligence</div>
                <img src={visualizationBackground} className="background-bg" style={{"width":"100%"}}/>

                <div className="para-text-h3" style={{'text-align':'left'}}>Visualization and data intelligence have transformed how companies understand their customers, track progress, and make decisions. <br/> Companies can quickly assess massive amounts of data using sophisticated computer software programs that generate eye-catching and informative visuals in order to reach meaningful conclusions and gain strategic advantages. </div>
            </div>
            <div className="div-60">
                <img src={visualizationBackground} className="display-overlay" style={{"width":"100%"}}/>
            </div>
            
        </div>
    );


}
export default Display;