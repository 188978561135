import React from "react";
import './SoftwareDevelopment.css';
import "@fontsource/red-hat-display";
import './../min.css';
import architecture_design from './resources/architecture_design.svg';
import devops from './resources/devops.svg';
import services from './resources/services.svg';
import aws from './resources/aws.svg';
import digitalOcean from './resources/digital-ocean.svg'
import googleCloud from './resources/google-cloud.svg'
import kubernetes from './resources/kubernetes.svg'
import liftShift from './resources/lift-shift.svg'
import projectConsulting from './resources/project-consulting.svg'
import monitoringAlert from './resources/monitoring-alert.svg'
import managedDevops from './resources/managed-devops.svg'
function DevOps(){
    return(
        <>

        <div style={{"width":"100%","text-align":"center","margin":"auto"}}>
        <h1>DevOps Automation</h1>
        <div className="row">
            <div className="box  box-md-3" style={{"box-shadow":"none"}}>
                    <img src={devops} style={{"width":"100%"}}/>
                </div>
                <div className="box box-md-3" style={{"box-shadow":"none"}}>
                    <div>
                    <div style={{"display":"flex","flex-direction":"row"}}> <img src={services}/><h1 style={{"margin":"20px"}}> Devops Automation</h1></div>
                    <p>Promote better collaboration between your software development and IT operations teams by adopting DevOps. Our experts can streamline your team’s adoption of DevOps practices. Combine Agile, automation, & continuous delivery to deliver better, more reliable products quicker.</p>
                    
                    </div>
                    <div>
                    <div style={{"display":"flex","flex-direction":"row"}}> <img src={kubernetes}/><h1 style={{"margin":"20px"}}> Kubernetes Migratons</h1></div>
                    <p>Migrating to Kubernetes has never been easier. Our Kubernetes migration packages can help your team save time & resources during the intense migration process. We use industry-grade Kubernetes container orchestration tools to help our clients migrate to Kubernetes with maximum security & convenience. </p>
                    
                    </div>
                    <div>
                    <div style={{"display":"flex","flex-direction":"row"}}> <img src={liftShift}/><h1 style={{"margin":"20px"}}> Lift And Shift</h1></div>
                    <p>We can help you move your applications to the cloud provider of your choice. With no downtime, we’ll help your team implement the best DevOps practices. Our Lift & Shift services are guaranteed to future-proof your business interests in the long run. </p>
                    
                    </div>
                </div>
                
                <div className="box box-md-3" style={{"box-shadow":"none"}}>
                <div>
                    <div style={{"display":"flex","flex-direction":"row"}}> <img src={managedDevops}/><h1 style={{"margin":"20px"}}> Managed Devops</h1></div>
                    <p>Get step-by-step assistance as you implement the latest DevOps practices & frameworks at your enterprise. You focus on your business. We’ll work alongside your team to handle day-to-day operations tasks, maintenance-related duties, performance tuning, & troubleshooting. </p>
                    
                    </div>
                    <div>
                    <div style={{"display":"flex","flex-direction":"row"}}> <img src={monitoringAlert}/><h1 style={{"margin":"20px"}}> Monitoring & Alert</h1></div>
                    <p>We can build customized & all-inclusive monitoring, logging, & alerting solutions for your enterprise. Our experts are available 24/7, 365-days of the year. Their emergency responses will ensure that your applications & software solutions are available & efficient at all times. </p>
                    
                    </div>
                    <div>
                    <div style={{"display":"flex","flex-direction":"row"}}> <img src={projectConsulting}/><h1 style={{"margin":"20px"}}> Project Consulting</h1></div>
                    <p>Implementing DevOps practices at your enterprise will become much easier once you consult with our DevOps specialists. We’ll handle your DevOps-related projects as your team focuses on core business tasks. Our DevOps consulting services are chargeable on an hourly basis. No project’s too big/small for us and we always deliver quality results at affordable rates.</p>
                    
                    </div>
                </div>
           </div>
           <div className="logo-row" style={{"width":"80%","margin":"auto"}}>
                <div className="" style={{"box-shadow":"none","margin":"auto","width":"33.33%"}}>
                <img src={aws} style={{"width":"80%"}}/>
                </div>
                <div className="" style={{"box-shadow":"none","margin":"auto","width":"33.33%"}}>
                <img src={digitalOcean} style={{"width":"80%"}}/>
                </div>
                <div style={{"box-shadow":"none","margin":"auto","width":"33.33%"}}>
                <img src={googleCloud} style={{"width":"80%"}}/>
                </div>

            </div>
        </div>
        </>

    );


}
export default DevOps;