import React from "react";
import './Virtualization.css';
import "@fontsource/red-hat-display";
import Display from "./Display";
import Services from "./Services";
import Like from './Like';
import Engineer from './Engineer';

function Virtualization(){
    return(
        <div>
        <Display/>
        <Services/>
        <Like/>
        {/* <Engineer/> */}
        </div>
    );


}
export default Virtualization;