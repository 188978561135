import React,{useEffect} from "react";
import './Visualization.css';
import "@fontsource/red-hat-display";
import './../min.css';
import decisionBoard from "./resources/decision-board.svg";
import agents from "./resources/agents.png";
import backend from "./resources/backend.png";
import collector from "./resources/collector.png";
import series from "./resources/series.png";

function    TruthBoard(){
    var coll = document.getElementsByClassName("dashboard-ul-truth");
    var dashboards=document.getElementsByClassName('dashboard_display');

    useEffect(()=>{
        var i;
        for (i = 0; i < coll.length; i++) {
            const clss='class'+i+'-truth';
            coll[i].addEventListener("click",function(event,cls=clss) {
                var j;
                for (j = 0; j < coll.length; j++) {
                    coll[j].classList.remove('highlight');
                    document.getElementsByClassName('class'+j+'-truth')[0].style.display="none";
                }
                this.classList.add("highlight");
                document.getElementsByClassName(cls)[0].style.display="block";
            });
            
          }
    })
    return(
        // <div className="body-deicison">
            
            
        //     <div className="logo-heading">
        //         <img src={visualizationBackground} style={{width:'100%',height:'300px'}}/>
        //     </div>
        //     <div>
        //         <div className="heading-text-h2" style={{"margin-bottom":"20px"}}>Visualization and</div>
        //         <div className="heading-text-h2" style={{"margin-bottom":"20px"}}>Data Intelligence</div>
        //         <div className="para-text-h3" style={{"font-size":"1.5vw",'text-align':'left'}}>Lorem Ipsum is simply dummy text of the printing <br/>and  typesetting industry. Lorem Ipsum has been <br/> the industry's standard dummy text ever since the 1500</div>
        //     </div>
        //     </div>
<div style={{'margin-bottom':'200px'}}>    
<div className="heading-text-h2" style={{'padding-bottom':'20px','text-align':'center','font-size':'45px'}}>Monitor Your Resources</div>
<div className="para-text-h3" style={{'text-align':'center'}}>Get all your resource from Cloud on a single platform</div>
<br/>
<div className="truthboard-nav">
        <p className="dashboard-ul-truth highlight">Install Agents</p>
        <p className="dashboard-ul-truth">Events from Agents</p>
        <p className="dashboard-ul-truth">Strong Backend</p>
        <p className="dashboard-ul-truth">Convert to Series</p>
        <p className="dashboard-ul-truth">Monitor</p>

</div>
<br/>
<br/>
<div className="dashboard_display class0-truth" style={{width:'80%','text-align':'center','margin-left':'10%','margin-bottom':'100px'}}>
    <img src={agents} style={{width:'100%'}}/>
   
</div>
<div className="dashboard_display class1-truth" style={{width:'80%','text-align':'center','margin-left':'10%','margin-bottom':'100px','display':'none'}}>
    <img src={collector} style={{width:'100%'}}/>
   
</div>
<div className="dashboard_display class2-truth" style={{width:'80%','text-align':'center','margin-left':'10%','margin-bottom':'100px','display':'none'}}>
    <img src={backend} style={{width:'100%'}}/>
   
</div>
<div className="dashboard_display class3-truth" style={{width:'80%','text-align':'center','margin-left':'10%','margin-bottom':'100px','display':'none'}}>
    <img src={series} style={{width:'100%'}}/>
   
</div>
<div className="dashboard_display class4-truth" style={{width:'80%','text-align':'center','margin-left':'10%','margin-bottom':'100px','display':'none'}}>
    <img src={decisionBoard} style={{width:'100%'}}/>
   
</div>






{/* <div className="truthboard-stack">
    <div className="truthboard-box" style={{width:'55%'}}>
        <div className="heading-text-h2" style={{'text-align':'left','font-size':'20px'}}>Request a Free Trial</div>
        <p className="para-text-h3">Join the Thousands of Business Leaders Winning With Grow</p>
        <br/><br/>
        <a href="/contact" className="source-button" style={{"background":"#000","text-decoration":"none"}}>Request a Free Trial</a>

    </div>
    <div className="truthboard-box" style={{width:'22%'}}>
        <div className="heading-text-h3" style={{'text-align':'left','font-size':'15px'}}>Build 5 Desktop on Cloud for your team for free</div>
        <p className="para-text-h3">Join the Thousands of Business Leaders Winning With Grow</p>
        <br/><br/>
        <a href="/contact" className="source-button" style={{"background":"#000","text-decoration":"none"}}>Request a Free Trial</a>

    </div>
    <div className="truthboard-box" style={{width:'22%'}}>
        <div className="heading-text-h3" style={{'text-align':'left','font-size':'15px'}}>Build 5 Desktop on Cloud for your team for free</div>
        <p className="para-text-h3">Join the Thousands of Business Leaders Winning With Grow</p>
        <br/><br/>
        <a href="/contact" className="source-button" style={{"background":"#000","text-decoration":"none"}}>Request a Free Trial</a>

    </div>
</div> */}

{/* <div className="truthboard-stack">
    <div className="truthboard-box" style={{width:'20%'}}>
        <div className="heading-text-h2" style={{'text-align':'left','font-size':'20px'}}>Request a Free Trial</div>
        <p className="para-text-h3">Join the Thousands of Business Leaders Winning With Grow</p>
        <br/>
        <a href="/contact" className="source-button" style={{"background":"#000","text-decoration":"none"}}>Request a Free Trial</a>

    </div>
    <div className="truthboard-box" style={{width:'20%'}}>
        <div className="heading-text-h3" style={{'text-align':'left','font-size':'15px'}}>Build 5 Desktop on Cloud for your team for free</div>
        <p className="para-text-h3">Join the Thousands of Business Leaders Winning With Grow</p>
    </div>
    <div className="truthboard-box" style={{width:'20%'}}>
        <div className="heading-text-h3" style={{'text-align':'left','font-size':'15px'}}>Build 5 Desktop on Cloud for your team for free</div>
        <p className="para-text-h3">Join the Thousands of Business Leaders Winning With Grow</p>
    </div>
    <div className="truthboard-box" style={{width:'20%'}}>
        <div className="heading-text-h3" style={{'text-align':'left','font-size':'15px'}}>Build 5 Desktop on Cloud for your team for free</div>
        <p className="para-text-h3">Join the Thousands of Business Leaders Winning With Grow</p>
    </div>
    <div className="truthboard-box" style={{width:'20%'}}>
        <div className="heading-text-h3" style={{'text-align':'left','font-size':'15px'}}>Build 5 Desktop on Cloud for your team for free</div>
        <p className="para-text-h3">Join the Thousands of Business Leaders Winning With Grow</p>
    </div>
</div> */}
</div>

            
    );


}
export default TruthBoard;