import React from "react";
import './AugmentedTeam.css';
import "@fontsource/red-hat-display";
import Display from "./Display";
import WhatIsGreat from './WhatIsGreat';
import Talent from './Talent';
import AmazingTeams from './AmazingTeams';
import UnderstandEmployees from './UnderstandEmployees';
import Contact  from "./Contact";
function AugmentedTeam(){
    return(
        <div>
        {/* <Display/>
        <WhatIsGreat/>
        <Talent/>
        <AmazingTeams/>
        <UnderstandEmployees/> */}
        <br/>
        <br/>
        <br/>
        <Contact/>
        </div>
    );


}
export default AugmentedTeam;