import React from "react";
import './Virtualization.css';
import "@fontsource/red-hat-display";
import './../min.css';
import sourceService from "./resources/source-service.svg";

import azure from "./resources/azure.svg";
import checkmark from "./resources/checkMark.svg"

import firebase from "./resources/firebase.svg"
import kubernetes from "./resources/kubernetes.svg"
import automation from "./resources/automation.svg"
import {Link} from 'react-router-dom'
function Services(){
    return(
        <>
        <div className="body-virtualization" style={{"top":"105px"}}>
            <div className="div-50">
                <h2>Open Source Services</h2>
                <img src={sourceService} className="background-bg"/>

                <p className="div-50-para">Open-source virtualization opens up our path to speedy innovation. Our open-source virtualization tools meet all standards of compliance and quality. Our open-source virtualization tools empower the simultaneous creation, implementation, and management of various VMs in one physical environment for very little cost.</p>
            </div>
            <div className="div-50">
                <img src={sourceService} className="display-overlay-bg" />
            </div>

        </div>
        <div className="body-virtualization">
            
            <div className="div-50">
                <img src={azure} className="display-overlay-bg" />
            </div>
            <div className="div-50">
                <h2>Build 5 desktops on cloud for your team for free.</h2>
                <p className="div-50-para">Need to support multiple members of your team on a single virtual machine (VM)? Spin up “new desktops” for your team members in an instant by leveraging the cloud. </p>
                <img src={azure} className="background-bg" />

                <div className="checkMarkDiv"><img src={checkmark} width="15" height="15"/> We setup your cloud Suscription </div>
                <div className="checkMarkDiv"><img src={checkmark} width="15" height="15"/>  You only bear the infra cost no service charges</div>

                <div className="checkMarkDiv"><img src={checkmark} width="15" height="15"/> secure Access </div>
                <br/>
                <Link to="../contact"> <div className="vir-button" style={{"width": "500px"}}>Share Your Requirements</div></Link>
            </div>

        </div>

        <div className="body-virtualization">
            
           
            <div className="div-50">
                <h2>Build kubernetes on cloud for your team for free.</h2>
                <p className="div-50-para">To manage your Containers, you’ll need Kubernetes: a portable and open-source platform boosting the efficiency of application development and deployment. With us, you can build Kubernetes on Cloud for your team for free. </p>
                <img src={kubernetes} className="background-bg" />

                <div className="checkMarkDiv"><img src={checkmark} width="15" height="15"/> Business-ready Kubernetes </div>
                <div className="checkMarkDiv"><img src={checkmark} width="15" height="15"/>  The ability to quickly automate your infrastructure </div>

                <div className="checkMarkDiv"><img src={checkmark} width="15" height="15"/> Upgrade from one or two deployments a month to multiple, fast deployments every day </div>
                <br/>
                <Link to="../contact"><div className="vir-button" style={{"width": "500px"}}>Share Your Requirements</div></Link>
            </div>
            <div className="div-50">
                <img src={kubernetes} className="display-overlay-bg" />

            </div>

        </div>

        <div className="body-virtualization">
            
            <div className="div-50">
                <img src={firebase} className="display-overlay-bg"/>
            </div>
            <div className="div-50">
                <h2>Host your website for free </h2>
                <p className="div-50-para">Scale your business with maximum efficiency by hosting your website on a fully-managed infrastructure for free. </p>
                <img src={firebase} className="background-bg" />

                <div className="checkMarkDiv"><img src={checkmark} width="15" height="15"/> We setup your cloud Suscription </div>
                <div className="checkMarkDiv"><img src={checkmark} width="15" height="15"/>  You only bear the infra cost no service charges</div>

                <div className="checkMarkDiv"><img src={checkmark} width="15" height="15"/> secure Access </div>
                <br/>
                <Link to="../contact"><div className="vir-button" style={{"width": "500px"}}>Share Your Requirements</div></Link>
            </div>
            

        </div>
        <div className="body-virtualization">
            
           
            <div className="div-50">
                <h2>Free automation scripts using terraform</h2>
                <p className="div-50-para">Terraform provides us with a well-defined and succinct way to deploy infrastructure resources and modifications. This open-source Infrastructure as Code (IaC) tool allows us to:</p>
                <img src={automation} className="background-bg"/>

                <div className="checkMarkDiv"><img src={checkmark} width="15" height="15"/> Prevent configuration drifts and establish identical instances across all of our infrastructures </div>
                <div className="checkMarkDiv"><img src={checkmark} width="15" height="15"/> Promote collaboration on infrastructure among team members </div>

                <div className="checkMarkDiv"><img src={checkmark} width="15" height="15"/> Save extra infrastructure and maintenance expenses  </div>
                <br/>
                <Link to="../contact"> <div className="vir-button" style={{"width": "500px"}}>Share Your Requirements</div></Link>
            </div>
            <div className="div-50">
                <img src={automation} className="display-overlay-bg"/>
            </div>

        </div>
        </>
    );


}
export default Services;