import React from "react";
import './LandingPage.css';
import "@fontsource/red-hat-display";
import confederacie from './resources/confederacie.svg';
import './../min.css';

function Display(){
    return(
        <div className="body-display fade-in" >
            
            <div style={{align:'left'}} className="div-50" >
                <div className="display-heading-text-h1" style={{color: "#007BB6","font-size": "4.625em"}}>Help Every <br/>Digital Dream</div>
                <div className="display-heading-text-h1 underline" style={{color: "#FDD231"}}>Come Alive</div>
                <p className="display-heading-tag-h1" style={{'margin-top':'40px','font-size': '1.55em','mergin-bottom':'40px'}}>It is our goal to actualize your digital dreams </p>
                <br/>
                <br/>
                <a href="https://github.com/confederacie" className="source-button" style={{'background':'#000','text-decoration':'none','font-size':'1.35em','font-weight':'900','margin-top':'30px'}}>Open Source Library</a>
            </div>

            <div className="div-50"  style={{'margin-top':'0vh'}}>
                <img src={confederacie} style={{height:'auto',width:'100%'}} className="div-50-img"/>
            </div>
            
        </div>
    );


}
export default Display;