import React from "react";
import './Prototyping.css';
import "@fontsource/red-hat-display";
import './../min.css';

import employee from './resources/employee.svg'
import sketch from './resources/sketch.svg'
import principle from './resources/principle.svg'
import invision from './resources/invision.svg'
import moqups from './resources/moqups.svg'
import abstract from './resources/abstract.svg'
import jira from './resources/jira.svg'
function Tools(){


    return(
<div style={{'width':'80%','margin':'auto','padding':'10%'}}> 
<br/>
<div className="heading-text-h2" style={{'padding-bottom':'20px','text-align':'center','font-size':'45px'}}>Tools We Use</div>
<div style={{'width':'100%','margin':'auto','margin':'20px','text-align':'center'}}>Get Everyone on Same Page looking at the right data</div>
<div className="row tools-row" style={{"width":"100%"}}>
                <div className="box box-md-6">
                    <img src={sketch} style={{width:'100%'}}/>
                    <h1 className="heading-text-h3">Sketch</h1>
                    
                </div>
                <div className="box box-md-6">
                    <img src={abstract} style={{width:'100%'}}/>
                    <h1 className="heading-text-h3">Abstract</h1>

                </div>
                <div className="box box-md-6">
                    <img src={principle} style={{width:'100%'}}/>
                    <h1 className="heading-text-h3">Principle</h1>
                   
                </div>
                <div className="box box-md-6">
                    <img src={invision} style={{width:'100%'}}/>
                    <h1 className="heading-text-h3">Invision</h1>
   
                </div>
                <div className="box box-md-6">
                    <img src={moqups} style={{width:'100%'}}/>
                    <h1 className="heading-text-h3">Moqups</h1>

                </div>
                <div className="box box-md-6">
                    <img src={jira} style={{width:'100%'}}/>
                    <h1 className="heading-text-h3">Jira</h1>

                </div>
            </div>
 </div>);
 }
export default Tools;