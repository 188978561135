import React,{useState,useRef,useEffect} from "react";
import './Virtualization.css';
import "@fontsource/red-hat-display";
import './../min.css';
import displayOverlay from "./resources/display-overlay.svg";
function Display(){

    const [slideIndex, setSlideIndex] = useState(0);
    const [slideCount,setSlideCount]=useState(0);
    const [n,setN]=useState(1);
    const stateRef = useRef();  
    const slider=document.getElementById('vir-slider');
    stateRef.current=slideIndex;
    const showDivs=(n)=>{
        if (slideIndex+n >= slideCount) {
            setSlideIndex(0);
           
        }    
        else if (slideIndex+n < 0) {
            setSlideIndex(slideCount-1);
        }
        else{
            setSlideIndex(slideIndex+n);
        }
        if(slider.style.flexDirection=="row"){
            slider.style.flexDirection="row-reverse"

        }
        else{
            slider.style.flexDirection="row"

        }
        setN(-n)
        
       
         
      }

      useEffect(()=>{
        var i;   
        var x = document.getElementsByClassName("virtualization-slides");
        var vir_h3=document.getElementsByClassName("vir-h3");
        for (i = 0; i < x.length; i++) {
            x[i].style.display = "none";
            vir_h3[i].className="vir-h3"
        }
        x[slideIndex].className="virtualization-slides fade-in";
        vir_h3[slideIndex].className="vir-h3 vir-active";

        x[slideIndex].style.display = "block";
        setSlideCount(document.getElementsByClassName("virtualization-slides").length);

      },[slideIndex]);
    return(
        <div className="body-virtualization">

            <div className="rectangle-display1">
                <h3 className="vir-h3 vir-active" onClick={()=>setSlideIndex(0)}>Virtualization</h3>
                <div className="vir-list">
                <h3 className="vir-h3" onClick={()=>setSlideIndex(1)}>Linux And Windows</h3>
                <h3 className="vir-h3" onClick={()=>setSlideIndex(2)}>Serverless</h3>
                <h3 className="vir-h3" onClick={()=>setSlideIndex(3)}>IaaS</h3>
                <h3 className="vir-h3" onClick={()=>setSlideIndex(4)}>PaaS</h3>
                <h3 className="vir-h3" onClick={()=>setSlideIndex(5)}>Container and Kubernetes</h3>



                </div>
            </div>
            <div className="rectangle-display2">
                <div id="virtualization-slides">
                    <div className="virtualization-slides">
                        <h2 className="display-virtualization-text">Virtualization</h2>
                    <img className="background-bg" src={displayOverlay}/>

                    <p className="display-virtualization-para">ONE PURPOSE… MULTIPLE SERVERS AND VIRTUAL MACHINES.<br/>With our VMs, you can reduce your firm’s hardware and energy costs. Our virtualized systems will also empower you to deploy new servers with ease. More importantly, they’ll boost your firm’s data security and your IT department’s operational efficiency.</p>
                    </div>  
                </div>
                <div className="virtualization-slides">
                        <h2 className="display-virtualization-text">Linux and Windows</h2>
                    <img className="background-bg" src={displayOverlay}/>

                    <p className="display-virtualization-para">We can build both Linux and Windows VMs for your company. You can easily migrate your business-critical workloads to these VMs and improve your team’s operational efficiency.</p>
                </div>
                    <div className="virtualization-slides">
                        <h2 className="display-virtualization-text">Serverless</h2>
                    <img className="background-bg" src={displayOverlay}/>

                    <p className="display-virtualization-para">Go serverless and pay only for the resources you consume. Our serverless cloud service model is designed to help developer teams do away with the tiresome task of managing server software and hardware. </p>
                </div>
 
                    <div className="virtualization-slides">
                        <h2 className="display-virtualization-text">Infrastructure As A Service</h2>
                    <img className="background-bg" src={displayOverlay}/>

                    <p className="display-virtualization-para">Need multiple operating systems with varying conﬁgurations to run simultaneously on your machines? Our IaaS cloud computing experts can deliver all the virtualized computing resources you need over the Internet.</p>
                    </div>  
                    <div className="virtualization-slides">
                        <h2 className="display-virtualization-text">Platform As A Service</h2>
                    <img className="background-bg" src={displayOverlay}/>

                    <p className="display-virtualization-para">Switch to the PaaS (Platform as a service) computing model to cut costs and improve your software development team’s efficiency. Our PaaS clients consistently receive advanced hardware and software tools for their application development needs over the Internet. </p>
                    </div>  
                    <div className="virtualization-slides">
                        <h2 className="display-virtualization-text">Container And Kubernetes</h2>
                    <img className="background-bg" src={displayOverlay}/>

                    <p className="display-virtualization-para">With our assistance, you can build business-ready Kubernetes on Cloud for your team for free. We can also help you build containers so that you can automate your infrastructure and leverage all the features of your host OS. </p>
                    </div>  
                </div>
            <img className="display-overlay" src={displayOverlay} style={{'position':'absolute'}}/>

        </div>
    );


}
export default Display;