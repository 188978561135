import React from "react";
import './SoftwareDevelopment.css';
import "@fontsource/red-hat-display";
import './../min.css';
import product_life from './resources/product_life.svg'
function Display(){
    return(
                    <>
        <div className="body-sd">

            <div className="div-60 div-num div-mob" style={{"color":"#5C86A7","vertical-align":"middle", margin: 'auto'}}>
            <div style={{margin:'auto','text-align':'center'}}><p className="heading-text-h2" style={{color:'#000'}}>Breathe <div style={{"color":"#6100FF",display:'inline',}}>Life, </div> <div style={{"color":"#FFA07A",display:'inline',}}>Excitement,</div> & <div style={{"color":"#32CD32",display:'inline',}}>Quality</div> into Your Products </p></div>
            <img src={product_life} className="background-bg"/>

            <div tyle={{'text-align':'center'}}><p className="para-text-h3" style={{color:'#000', 'float':'left'}}>Give your end products sturdy foundations. Take architectural decisions that align you’re your organizational objectives. Our detailed architectural blueprints will ensure that your digital products consistently meet all the important technical/operational requirements while safeguarding flawless performances and convenient user experiences.  </p></div>
            </div>
            <div className="div-40" style={{"width":"auto"}}>
                <img src={product_life} className="display-overlay" style={{"width":"100%","left":"0%"}}/>
            </div>

        </div></>
    );


}
export default Display;