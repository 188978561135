import React,{useState,useEffect,useRef,useCallback} from "react";
import './css/fourthDisplay.css';
import "@fontsource/red-hat-display";
import bgPrototype from './resources/bg-prototype.svg';
import explorArrow from './resources/explore-arrow.svg';
import protoTypeStackConnect1 from './resources/prototype-stack-connect1.svg';
import protoTypeStackConnect2 from './resources/prototype-stack-connect2.svg';
import {Link} from 'react-router-dom';
function FourthDisplay(){

    return (
    <div className="fourthDisplay">
        <img src={bgPrototype} className="backgroundImage-prototype"/>
        <div className="prototype">
            <p>Build Your   <br/><prototype style={{color:'#35BBEA'}}>'Prototype'</prototype></p>
            
            <div className="prototype-flex">                
            <img src={protoTypeStackConnect1} style={{position:'absolute', top:'360px',left:'270px',width:'250px',height:'300px','z-index':'0.01'}}/>

                <div className="prototype-stack" style={{ top:'50px',left:'0px' }}>
                    <div className="color-tag" style={{'background-color':'#1B8036'}}>
                    </div>
                        <h3>Product Discovery</h3>
                        <p>Identification of technical requirements and verification of the feasibility of ideas</p>
                        <br/>
                        <Link to="prototyping"><img src={explorArrow}/> <arrow> Explore</arrow></Link>
                </div>            
                <img src={protoTypeStackConnect1} style={{position:'absolute', top:'620px',left:'600px',width:'200px',height:'300px','z-index':'0.01'}}/>

                <div className="prototype-stack" style={{ top:'-50px',left:'300px' }}>
                <div className="color-tag" style={{'background-color':'#FFE600'}}></div>
                <h3>Ideation and Prototyping</h3>
                <p>Get access to wireframes and clickable prototypes of digital products</p>
                    <br/>
                    <Link to="prototyping"><img src={explorArrow}/> <arrow> Explore</arrow></Link>
                    </div>
                    <img src={protoTypeStackConnect2} style={{position:'absolute', top:'970px',left:'670px',width:'121px',height:'212px','z-index':'0.01'}}/>

                <div className="prototype-stack" style={{ top:'-130px',left:'650px' }}>
                <div className="color-tag" style={{'background-color':'#FF0000'}}></div>
                    <h3>User Interface Design</h3>
                    <p>Emphasis on creating visual brand identities that help your product stand out</p>
                    <br/>
                    <Link to="prototyping"><img src={explorArrow}/> <arrow> Explore</arrow></Link>
                </div>



                    <div className="prototype-stack" style={{ top:'-200px',left:'300px' }}>
                    <div className="color-tag" style={{'background-color':'#3A66FF'}}></div>
                    <h3>Testing and Evaluation</h3>
                    <p>Identification of errors/inconsistencies through user testing and layout testing</p>
                    <br/>
                    <Link to="prototyping"><img src={explorArrow}/> <arrow> Explore</arrow></Link>
                    </div>
                    <div className="prototype-stack" style={{ top:'-300px',left:'0px' }}>
                    <div className="color-tag" style={{'background-color':'#F800FD'}}></div>
                    <h3>Deliver</h3>
                    <p>Get your final Idea to stead fast and deliver it's true intention</p>
                    <br/>
                    <Link to="prototyping"><img src={explorArrow}/> <arrow> Explore</arrow></Link>
                </div>

                
        </div>
        </div>
        <a href="prototyping"><div className="source-button learn-more" style={{'background-color':'#000000'}}>Learn More</div></a>

    </div>);
}
export default FourthDisplay;
