import React, { useEffect,useLayoutEffect,useState } from "react";
import './Governance.css';
import "@fontsource/red-hat-display";
import './../min.css';
import employee from './resources/employee.svg';
import leftArrow from './resources/left-arrow.svg'
import rightArrow from './resources/right-arrow.svg'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

function Works(){
    
    const [slider,setSlider]=useState(null)
    const [size, setSize] = useState(2.3);
    useLayoutEffect(() => {
      function updateSize() {
        if (window.innerWidth<699){
            setSize(1);

        }
        else{
            setSize(2.3);
        }
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
  
    // useEffect(()=>{
    // var swiper = new window.Swiper(".mySwiper", {
    //     slidesPerView: 3,
    //     spaceBetween: 30,
    //     slidesPerGroup: 1,
    //     loop: true,
    //     loopFillGroupWithBlank: true,
    //     pagination: {
    //       el: ".swiper-pagination",
    //       clickable: true,
    //     },
    //     navigation: {
    //       nextEl: ".swiper-button-next",
    //       prevEl: ".swiper-button-prev",
    //     },
    //   })}
    //   );
    return(
        <div className="body-empower owl-theme"  style={{width:'100%'}}>
            <div className="div-pane" >
                <br/>
                <div className="heading-text-h2" style={{"margin-bottom":"20px"}}>
                        <p>Here is</p>How it works
                    <p className="para-text-h3">Endpoint security measures, shift left, live monitoring, & continuous testing are some of the key components of our security setups.  By implementing an end-to-end Dev-Sec-Ops framework in your enterprise, we can guarantee heightened security for your customers.</p>
                    <div className="owl-nav">
                    <div className="owl-prev"> <img src={leftArrow} onClick={()=>{if(slider!=null){slider.prev()}}}/> </div>
                    <div className="owl-next">
                    <img src={rightArrow} onClick={()=>{if(slider!=null){slider.next()}}}/></div>
                    </div>
                </div>
            </div>

    <OwlCarousel className='owl-theme' loop margin={10} items={size} dots={false} ref={slider=>setSlider(slider)}>

          <div className="box               ">
                <img src={employee} style={{width:'100%'}}/>
                <h1 className="heading-text-h3">Dilip Yadav</h1>
                <h3 className="heading-text-h3">Singer</h3>
                <p className="para-text-h3">Our vision is to help every digital dream come alive snv dsljf nv mdsfinI, sdnf II fkdsjfl </p> 
            </div>
            <div className="box">
                <img src={employee} style={{width:'100%'}}/>
                <h1 className="heading-text-h3">Dilip Yadav</h1>
                <h3 className="heading-text-h3">Singer</h3>
                <p className="para-text-h3">Our vision is to help every digital dream come alive snv dsljf nv mdsfinI, sdnf II fkdsjfl </p> 
            </div>
            <div className="box ">
                <img src={employee} style={{width:'100%'}}/>
                <h1 className="heading-text-h3">Dilip Yadav</h1>
                <h3 className="heading-text-h3">Singer</h3>
                <p className="para-text-h3">Our vision is to help every digital dream come alive snv dsljf nv mdsfinI, sdnf II fkdsjfl </p> 
            </div>
            <div className="box ">
                <img src={employee} style={{width:'100%'}}/>
                <h1 className="heading-text-h3">Dilip Yadav</h1>
                <h3 className="heading-text-h3">Singer</h3>
                <p className="para-text-h3">Our vision is to help every digital dream come alive snv dsljf nv mdsfinI, sdnf II fkdsjfl </p> 
            </div>
            <div className="box ">
                <img src={employee} style={{width:'100%'}}/>
                <h1 className="heading-text-h3">Dilip Yadav</h1>
                <h3 className="heading-text-h3">Singer</h3>
                <p className="para-text-h3">Our vision is to help every digital dream come alive snv dsljf nv mdsfinI, sdnf II fkdsjfl </p> 
            </div>
            <div className="box">
                <img src={employee} style={{width:'100%'}}/>
                <h1 className="heading-text-h3">Dilip Yadav</h1>
                <h3 className="heading-text-h3">Singer</h3>
                <p className="para-text-h3">Our vision is to help every digital dream come alive snv dsljf nv mdsfinI, sdnf II fkdsjfl </p> 
            </div>
        </OwlCarousel>
</div>
        
    // <section>
    // <div className="swiper mySwiper container">
    //     <div className="swiper-wrapper content">
    //             <div className="swiper-slide card">
    //                 <div className="card-content">
    //                 <div className="image">
    //                 <img src={WorksBackground} alt=""/>
    //                 </div>

    //                 <div className="media-icons">
    //                 <i className="fab fa-facebook"></i>
    //                 <i className="fab fa-twitter"></i>
    //                 <i className="fab fa-github"></i>
    //                 </div>

    //                 <div className="name-profession">
    //                 <span className="name">Dominic Wells</span>
    //                 <span className="profession">Software Engineer</span>
    //                 </div>

    //                 <div className="rating">
    //                 <i className="fas fa-star"></i>
    //                 <i className="fas fa-star"></i>
    //                 <i className="fas fa-star"></i>
    //                 <i className="fas fa-star"></i>
    //                 <i className="fas fa-star"></i>
    //                 </div>

    //                 <div className="button">
    //                 <button className="aboutMe">About Me</button>
    //                 <button className="hireMe">Hire Me</button>
    //                 </div>
    //                 </div>
    //             </div>
    //             <div className="swiper-slide card">
    //                 <div className="card-content">
    //                 <div className="name-profession">
    //                 <span className="name">Ally Pearson</span>
    //                 <span className="profession">Web Developer</span>
    //                 </div>
    //             </div>
    //                 </div>
    //                 <div className="swiper-slide card">
    //                 <div className="card-content">
    //                 <div className="name-profession">
    //                 <span className="name">Ally Pearson</span>
    //                 <span className="profession">Web Developer</span>
    //                 </div>
    //                 </div></div>
    //                 <div className="swiper-slide card">
    //                 <div className="card-content">
    //                 <div className="name-profession">
    //                 <span className="name">Ally Pearson</span>
    //                 <span className="profession">Web Developer</span>
    //                 </div>
    //                 </div></div>
    //                 <div className="swiper-slide card">
    //                 <div className="card-content">
    //                 <div className="name-profession">
    //                 <span className="name">Ally Pearson</span>
    //                 <span className="profession">Web Developer</span>
    //                 </div>
    //                 </div>
    //                 </div>
    //                 <div className="swiper-slide card">
    //                 <div className="card-content">
    //                 <div className="name-profession">
    //                 <span className="name">Ally Pearson</span>
    //                 <span className="profession">Web Developer</span>
    //                 </div>
    //                 </div>
    //                 </div>
    //                 <div className="swiper-slide card">
    //                 <div className="card-content">
    //                 <div className="name-profession">
    //                 <span className="name">Ally Pearson</span>
    //                 <span className="profession">Web Developer</span>
    //                 </div>
    //                 </div></div>
    //             {/* <div className="swiper-slide card">
    //                 <h1 className="heading-text-h3">Vikhyat Shetty</h1>
    //                 <h3 className="heading-text-h3">Cloud Engineer</h3>
    //                 <p className="para-text-h3">Our vision is to help every digital dream come alive snv dsljf nv mdsfinI, sdnf II fkdsjfl </p>
    //             </div>
    //             <div className="swiper-slide card">
    //                 <h1 className="heading-text-h3">Vikhyat Shetty</h1>
    //                 <h3 className="heading-text-h3">Cloud Engineer</h3>
    //                 <p className="para-text-h3">Our vision is to help every digital dream come alive snv dsljf nv mdsfinI, sdnf II fkdsjfl </p>
    //             </div> */}
    //     </div>
    //     </div>
    //     <div className="swiper-button-next"></div>
    //   <div className="swiper-button-prev"></div>
    //   <div className="swiper-pagination"></div>
    //   </section>

    );


}
export default Works;