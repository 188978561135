import React from "react";
import './Virtualization.css';
import "@fontsource/red-hat-display";
import './../min.css';
import employee from './resources/employee.svg'
import virNet from './resources/vir-network.svg'
import devops from './resources/devops.svg'
import dv from './resources/dv.svg'
import govIc from './resources/governance-icon.svg'

function More(){


    return(
< div className="works-gvernance" style={{'text-align':'center'}}> 
<br/>
<div className="heading-text-h2" style={{'padding-bottom':'20px','text-align':'center','font-size':'45px'}}>You May Also Like</div>
<div className="row">
                <div className="box box-md-4" style={{"text-align": "center"}}>
                    <img src={virNet} style={{width:'50%',"text-align": "center"}}/>
                    <h1 className="heading-text-h3">Virtualization and Networking</h1>
                    <p className="para-text-h3">Achieve agility and gain better control of your networking footprint and infrastructure scaling with our premium-grade Network Virtualization services.</p> 
                </div>
                <div className="box box-md-4" style={{"text-align": "center"}}>
                    <img src={devops} style={{width:'50%'}}/>
                    <h1 className="heading-text-h3">DevOps</h1>
                    <p className="para-text-h3">Get access to a full-stack networking platform with autonomous capabilities, Machine Learning, and multi-cloud support.</p> 
                
                </div>
                <div className="box box-md-4" style={{"text-align": "center"}}>
                    <img src={dv} style={{width:'50%'}}/>
                    <h1 className="heading-text-h3">Data Visualization and Analytics</h1>
                    <p className="para-text-h3">Gain a 360° view of your business by transforming raw data into visual data and visual data into actionable insights.</p> 
                
                </div>
                <div className="box box-md-4" style={{"text-align": "center"}}>
                    <img src={govIc} style={{width:'50%'}}/>
                    <h1 className="heading-text-h3">Governance and Security</h1>
                    <p className="para-text-h3">Maintain a model of data accountability by acquiring the data you need and then making it secure, accurate, and accessible for your teams. </p> 
                
                </div>
            </div>
 </div>);
 }
export default More;