import React from "react";
import './Prototyping.css';
import "@fontsource/red-hat-display";

import ui_ux_design from './resources/ui-ux-design.svg';
import ux_audit from './resources/ux-audit.svg';
import product_discovery from './resources/product-discovery.svg';
import works from './resources/works.svg'
import works_mob from './resources/works-mob.svg'
import ideation from './resources/ideation.svg'
import uid from './resources/uid.svg'
import testing from './resources/testing.svg'
import {Link} from 'react-router-dom'
import './../min.css';
function Works(){
    return(
        <>
        <div>
        <div className="body-works">

            <div className="div-40 div-num div-mob" style={{"color":"#5C86A7"}}>
            <h1>How We Work.</h1>
            </div>
            <img src={works_mob} className="background-bg" style={{"margin":"10%","width":"80%"}}/>
            <br/>
            <div className="div-60 div-num div-mob">
            <h3 style={{"color":"#5C86A7","padding":"10%;"}}>Designing intuitive and attractive digital products requires comprehensive preparation and deep design expertise. </h3>
            <p className="checkMarkDiv">Our product design team meets all these criteria. Our multi-stage approach to creating digital products helps us meet our customers’ business and user needs with maximum efficiency. Here’s a brief gist of our workflow. </p>
           
        
        </div>

        </div>
        <br/>
        <br/>
        <div style={{"width":"80%","margin":"auto"}}>
            <img src={works} className="display-block-overlay" style={{width:"100%"}}/>
        </div>
        </div>
        <br/>
        <br/>
        <br/>


        <div className="body-prototyping-bg">

            <div className="div-50 div-num div-mob">
            <h2>Product Discovery</h2>
                <img src={product_discovery} className="background-bg" style={{"widht":"100%","align":"right","width":"100%",padding:"0%","padding-top":"10%","top":"10%"}}  />
                {/* <p className="background-bg" style={{"color":"#5C86A7","font-size":"25px","align":"right","padding":"0%","margin":"0%"}}>Process</p> */}
                <div  className="background-bg mob-display" style={{"text-align":"right",}}><div className="vir-button" style={{"background":"#fff","color":"#008FFF"}}>Research and Analysis</div></div>
                <div className="background-bg mob-display"><div className="vir-button" style={{"background":"#fff","color":"#008FFF"}}>Scope Definition</div></div>

                <p className="div-50-para">This stage is all about deep research. We’ll first define your firm’s key technological requirements as it aims to achieve specific business goals. Then, we’ll assess the technical feasibility of your brand ideas to focus on the right design direction right from the beginning. </p>
                <p className="checkMarkDiv">Deliverables </p>
                <ul>
                    <li>Lean Canvas or Business Model Canvas</li>
                    <li>Value Chain</li>

                    <li>UX Review of existing functionality and competitive analysis report</li>
                    <li>User Personas</li>
                    <li>Feature breakdown list</li>
                    <li>Functional and non-functional specifications and requirements</li>
                </ul>
                <p className="div-50-para" style={{"color":"#5C86A7"}} >Learn more about our product design discovery phase</p>

                <br/>
                <Link to="../contact"> <div className="vir-button">Share You requirements</div></Link>
            </div>
            <div className="div-50 div-num display-block-overlay">
                <img src={product_discovery} className="display-block-overlay" style={{"align":"right",padding:"0%","padding-top":"10%","top":"10%"}}  />
                <p className="display-block-overlay" style={{"color":"#5C86A7","font-size":"25px","align":"right"}}>Process</p>
                <div  className="display-block-overlay " style={{"text-align":"right",}}><div className="vir-button" style={{"background":"#fff","color":"#008FFF"}}>Research and Analysis</div></div>
                <div className="display-block-overlay"><div className="vir-button" style={{"background":"#fff","color":"#008FFF"}}>Scope Definition</div></div>

            </div>
    
        </div>
        <div className="body-works">
            <div className="div-50 div-num" style={{"float":"left"}}>
            <img src={ideation} className="display-block-overlay" style={{"width":"100%","align":"right",padding:"0%","padding-top":"10%","top":"10%"}}  />
                <p className="display-block-overlay" style={{"color":"#5C86A7","font-size":"25px","align":"right"}}>Process</p>
                <div  className="display-block-overlay" style={{"text-align":"right",}}><div className="vir-button" style={{"background":"#fff","color":"#008FFF"}}>Information Architecture Design</div></div>
                <div className="display-block-overlay"><div className="vir-button" style={{"background":"#fff","color":"#008FFF"}}>UX Writing</div></div>

                </div>
    <div className="div-50 div-num div-mob">
    <h2>Ideation and Prototyping</h2>
                <img src={ideation} className="background-bg" style={{"width":"100%","align":"right",padding:"0%","padding-top":"10%","top":"10%"}}  />
                {/* <p className="background-bg" style={{"color":"#5C86A7","font-size":"25px","align":"right","padding":"0%","margin":"0%"}}>Process</p> */}
                <div  className="background-bg mob-display" style={{"text-align":"right",}}><div className="vir-button" style={{"background":"#fff","color":"#008FFF"}}>Information Architecture Design</div></div>
                <div className="background-bg mob-display"><div className="vir-button" style={{"background":"#fff","color":"#008FFF"}}>UX Writing</div></div>

        <p className="div-50-para">Once your project is defined & the research stage is complete, our designers will proceed to design the information architecture (IA) of your digital product. This IA will feature a clear taxonomy, a well-defined site map, & definite user-flows. Based on these details, we’ll create clickable prototypes & wireframes for your product. </p>
        <p className="checkMarkDiv">Deliverables </p>
        <ul>
            <li>User Flows</li>
            <li>Content Inventory</li>

            <li>Recommended site map</li>
            <li>Wireframes and clickable prototypes</li>
            <li>Created text for user interface</li>
        </ul>
        <p  className="div-50-para" style={{"color":"#5C86A7"}}>Learn more about our product design discovery phase</p>

        <br/>
        <Link to="../contact"> <div className="vir-button">Share You requirements</div></Link>
</div>


        </div>
        <div className="body-prototyping-bg">

        <div className="div-60 div-num div-mob">
         <h2>User Interface Design</h2>
            <img src={uid} className="background-bg" style={{"widht":"100%","align":"right","width":"100%",padding:"0%","padding-top":"10%","top":"10%"}}  />
            {/* <p className="background-bg" style={{"color":"#5C86A7","font-size":"25px","align":"right","padding":"0%","margin":"0%"}}>Process</p> */}
            <div  className="background-bg mob-display" style={{"text-align":"right",}}><div className="vir-button" style={{"background":"#fff","color":"#008FFF"}}>Research Analyst</div></div>
            <div className="background-bg mob-display"><div className="vir-button" style={{"background":"#fff","color":"#008FFF"}}>Scope Definition</div></div>

            <p>Our User Interface (UI) design services are heavily focused on establishing visual brand identities that make our customers’ products stand out. In this stage, our designers will define your product's visual layout. They’ll establish a distinct visual language for your product. They’ll also build mockups, graphics, etc., for your product. </p>
            <p className="checkMarkDiv">Deliverables</p>
            <ul>
                <li>Mood-board & concept</li>
                <li>Interactive prototypes and mockups for all resolutions</li>

                <li>UI assets (icons, banners, patterns)</li>
                <li>Illustrations</li>
                <li>Animation of interaction</li>
                <li>Design specifications (style, guide, UI Kit)</li>
            </ul>
            <p style={{"color":"#5C86A7"}} >Learn more about our product design discovery phase</p>

            <br/>
            <Link to="../contact"> <div className="vir-button">Share You requirements</div></Link>
        </div>
        <div className="div-40 div-num display-block-overlay">
            <img src={uid} className="display-block-overlay" style={{"align":"right",padding:"0%","padding-top":"10%","top":"10%"}}  />
            <p className="display-block-overlay" style={{"color":"#5C86A7","font-size":"25px","align":"right"}}>Process</p>
            <div  className="display-block-overlay" style={{"text-align":"right",}}><div className="vir-button" style={{"background":"#fff","color":"#008FFF"}}>Research Analyst</div></div>
            <div className="display-block-overlay"><div className="vir-button" style={{"background":"#fff","color":"#008FFF"}}>Scope Definition</div></div>

        </div>
    
        </div>
        <div className="body-works">
        <div className="div-40 div-num" style={{"float":"left"}}>
        <img src={testing} className="display-block-overlay" style={{"align":"right",padding:"0%","padding-top":"10%","top":"10%"}}  />
            <p className="display-block-overlay" style={{"color":"#5C86A7","font-size":"25px","align":"right"}}>Process</p>
            <div  className="display-block-overlay" style={{"text-align":"right",}}><div className="vir-button" style={{"background":"#fff","color":"#008FFF"}}>Research Analyst</div></div>
            <div className="display-block-overlay"><div className="vir-button" style={{"background":"#fff","color":"#008FFF"}}>Scope Definition</div></div>

            </div>
<div className="div-60 div-num div-mob">
            <h2>Testing and Evaluation</h2>
            <img src={testing} className="background-bg" style={{"widht":"100%","align":"right","width":"100%",padding:"0%","padding-top":"10%","top":"10%"}}  />
            {/* <p className="background-bg" style={{"color":"#5C86A7","font-size":"25px","align":"right","padding":"0%","margin":"0%"}}>Process</p> */}
            <div  className="background-bg mob-display" style={{"text-align":"right",}}><div className="vir-button" style={{"background":"#fff","color":"#008FFF"}}>Research Analyst</div></div>
            <div className="background-bg mob-display"><div className="vir-button" style={{"background":"#fff","color":"#008FFF"}}>Scope Definition</div></div>

    <p>After concluding the development process, our team of UI/UX designers will team up with top QA specialists (Quality Assurance) to conduct a series of tests. These tests will reveal any inconsistencies or errors in your product. We’ll fix these errors to ensure the product functions as intended.</p>
    <p className="checkMarkDiv">Deliverables </p>
    <ul>
        <li>Report with all the issues found</li>
        <li>Layout Testing</li>

        <li>User Testing (Optional)</li>
    </ul>
    <p style={{"color":"#5C86A7"}}>Learn more about our product design discovery phase</p>

    <br/>
    <Link to="../contact"> <div className="vir-button">Share You requirements</div></Link>
</div>


        </div>
    </>

    );
}
export default Works;
            
           
