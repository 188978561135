import React from "react";
import './Governance.css';
import "@fontsource/red-hat-display";
import './../min.css';
import Group6 from './resources/Group6.png';
import Group5 from './resources/Group5.png';
import Group7 from './resources/Group7.png';
import Group8 from './resources/Group8.png';
import Group10 from './resources/Group10.png';
import Group9 from './resources/Group9.png';

import explorArrow from './resources/explore-arrow.svg';
import {Link} from 'react-router-dom'
function Security(){
    return(
<div className="works-governance">    
<div className="heading-text-h2" style={{'padding-bottom':'20px','padding':'5%','text-align':'center','font-size':'45px'}}>Complete DevOps Security Governance</div>
<div className="row" style={{width:'80%',"margin":"auto"}}>
    <div className="row truth-box" style={{width:'25%'}}>
        <div className="box box-md-1">
            <div className="color-box-shade">
                <img src={Group6} style={{"max-width":"100%"}}/>
                <div>
                <p className="heading-text-h2" style={{'text-align':'left','font-size':'25px'}}>Track Business Performances</p>
                <p className="para-text-h3">We’ll give you access to the latest tracking tools. Gain real-time oversight over your key business metrics & performance indicators to consistently improve your app delivery pipelines.</p>
                <Link to="../contact"><div style={{display:'flex','flex-direction':'column'}}><arrow> Explore</arrow><img src={explorArrow}/> </div></Link>
                </div>
            </div>
            
        </div>
    </div>
    <div className="truth-box" style={{width:'75%'}}>
    <div className="row " style={{width:'100%'}}>
        
    <div className="box box-md-3">
            <div className="color-box-shade">
                <img src={Group5}/>
                
                    <p className="heading-text-h2" style={{'text-align':'left','font-size':'25px'}}>Alerts & Notifications</p>
                    <p className="para-text-h3">Our monitoring tools will dynamically monitor & instantly inform you of any vulnerabilities in your security setup. </p>
                    <Link to="../contact"> <div style={{display:'flex','flex-direction':'column'}}><arrow> Explore</arrow><img src={explorArrow}/> </div></Link>
            </div>
            
        </div>        
        <div className="box box-md-3">
            <div className="color-box-shade">
                <img src={Group7}/>
                
                    <p className="heading-text-h2" style={{'text-align':'left','font-size':'25px'}}>Rapid Software Delivery</p>
                    <p className="para-text-h3">Security problems are one of the major causes of delays in most DevOps environments. Our security solutions are changing this trend.</p>
                    <Link to="../contact"><div style={{display:'flex','flex-direction':'column'}}><arrow> Explore</arrow><img src={explorArrow}/> </div></Link>
            </div>
            
        </div>          
        <div className="box box-md-3">
            <div className="color-box-shade">
                <img src={Group8}/>
                
                    <p className="heading-text-h2" style={{'text-align':'left','font-size':'25px'}}>No More Expensive Code Fixes </p>
                    <p className="para-text-h3">Fixing codes right after a security breach is an expensive and resource-consuming process. Our security patches will minimize such risks at your firm.</p>
                    <Link to="../contact"><div style={{display:'flex','flex-direction':'column'}}><arrow> Explore</arrow><img src={explorArrow}/> </div></Link>
            </div>
            
        </div>      </div>
    <div className="row" style={{width:'100%'}}>
        
    <div className="box box-md-4">
            <div className="color-box-shade">
                <img src={Group9}/>
                
                    <p className="heading-text-h2" style={{'text-align':'left','font-size':'25px'}}>DevOps Security Assessment </p>
                    <p className="para-text-h3">Our in-depth DevOps security audit will reveal all the gray areas in your enterprise’s security measures. Once we reveal these gray areas, we’ll provide the right tools to correct them & give your enterprise impeccable DevOps security.</p>
                    <Link to="../contact"> <div style={{display:'flex','flex-direction':'column'}}><arrow> Explore</arrow><img src={explorArrow}/> </div></Link>
            </div>
            
        </div>          
        <div className="box box-md-4">
            <div className="color-box-shade">
                <img src={Group10}/>
                
                    <p className="heading-text-h2" style={{'text-align':'left','font-size':'25px'}}>Devop<br/>Governance</p>
                    <p className="para-text-h3">Join the Thousands of Business Leaders Winning With Grow</p>
                    <Link to="../contact">  <div style={{display:'flex','flex-direction':'column'}}><arrow> Explore</arrow><img src={explorArrow}/> </div></Link>
            </div>
            
        </div>  
    </div>
    </div>
    </div>
<div className="works-background"></div>
<br/>
<br/>
<br/>
</div>
    );


}
export default Security;