import React from "react";
import './SoftwareDevelopment.css';
import "@fontsource/red-hat-display";
import './../min.css';
import architecture_design from './resources/architecture_design.svg';
import product_life_computer from './resources/product_life_computer.svg';
import kubernetes from './resources/kubernetes.svg';
import productLife from './resources/product-life.svg'
function ArchitectureDesign(){
    return(
        <>        <div style={{"padding-left":"5%"}}>
            <h1>Architecture & Design</h1>

        </div>
        <div className="bodyArchDesign">

        <div className="body-sd">

            <div className="div-60 div-num" style={{"color":"#5C86A7","vertical-align":"middle",margin:'auto'}}>
            <div style={{margin:'auto','text-align':'center'}}><p className="heading-text-h2" style={{color:'#000'}}>Give your product a <div style={{"color":"#6100FF",display:'inline',}}>life.</div></p></div>
            <img src={productLife} className="background-bg"/>

            <div tyle={{'text-align':'center'}}><p className="para-text-h3" style={{color:'#000', 'float':'left'}}>Lorem Ipsum is simply dummy text of the printing <br/>and  typesetting industry. Lorem Ipsum has been <br/> the industry's standard dummy text ever since the 1500</p></div>
            </div>
            <div className="div-40">
                <img src={productLife} className="display-overlay" style={{"width":"80%"}}/>
            </div>

        </div>
        <br/>
        <div style={{"width":"100%","text-align":"center","margin":"auto","top":"50px","position":"relative"}}>
        <h1>Give you Product A Life</h1>
        <div className="row" style={{"width":"100%"}}>
                <div className="box box-md-3" style={{"box-shadow":"none"}}>
                    <h1>Improve the quality, maintainability, & performances of your final products. </h1>
                    <p>Premium-grade architecture is the hall-mark of a premium-grade system. With our overarching architectural assistance, your teams will always steer away from bad software development decisions. We’ll also help your teams combine Agile, Continuous Delivery, & iterative app development with programmable infrastructure deployment & automation.</p>  
                    
                    <br/>
                    <ol>
                        <li className="bg-black">Automate processes that have historically been slow, manual, & resource-intensive</li>
                        <li className="bg-black">Create, deliver, & deploy reliable products at a rapid rate.</li>
                        <li className="bg-black">Empower your teams to work faster & smarter</li>
                        <li className="bg-black">Optimize your business with an arsenal of proven tools and technologies. </li>

                    </ol>
                </div>
                <div className="box box-md-3" style={{"box-shadow":"none"}}>
                    <img src={product_life_computer} style={{"width":"100%"}}/>
                </div>
                <div className="box box-md-3" style={{"box-shadow":"none"}}>
                <h1>Platforms</h1>
                    
                <div>
                    <div style={{"display":"flex","flex-direction":"row"}}> <img src={kubernetes}/><h1 style={{"margin":"10px"}}> Backend</h1></div>
                    <p>We provide full-cycle backend development services. We can help you write your business logic and can also help you modernize your legacy software or migrate to a more modern backend architecture to ensure you stay competitive. </p>
                    
                    </div>
                    <div>
                    <div style={{"display":"flex","flex-direction":"row"}}> <img src={kubernetes}/><h1 style={{"margin":"10px"}}> Front-end</h1></div>
                    <p>We deliver high-quality visual experiences that boost user engagement. Combine your ideas and our frontend design skills to create interactive web and mobile applications that retain your users’ attention. </p>
                    
                    </div>
                    <div>
                    <div style={{"display":"flex","flex-direction":"row"}}> <img src={kubernetes}/><h1 style={{"margin":"10px"}}> Infrastructure</h1></div>
                    <p>We can build a secure, reliable, and easily scalable infrastructure to help you flexibly expand your business operations. Our engineers can also help you manage your existing infrastructure or migrate an existing legacy system into the cloud. We can also help you migrate on-premise enterprise systems into cloud-native architecture.</p>
                    
                    </div>
                </div>
           </div>


        </div>
        </div></>
    );


}
export default ArchitectureDesign;