import React from "react";
import './Prototyping.css';
import "@fontsource/red-hat-display";
import './../min.css';
import uiux_background from './resources/uiux-background.svg';
function Display(){
    return(
        <div className="body-prototyping">
 
            <div className="rectangle-display">
            <br/>
                <div className="heading-text-h2" style={{"margin":"10%"}}>
                Test your ideas and designs
                    <img src={uiux_background} className="background-bg"/>

                    <p className="para-text-h3">First-hand insights into how your users will interact with, and react to, the product you’re designing. </p>
                </div>
            </div>
            <div className="rectangle-display">
                <img src={uiux_background} className="display-overlay"/>
            </div>
        </div>
    );


}
export default Display;