import React from "react";
import './AugmentedTeam.css';
import "@fontsource/red-hat-display";
import './../min.css';
import "@fontsource/inter";
import augmented_bg from './resources/augmented_bg.svg'
import developers from './resources/developers.svg';
import amazing_teams from './resources/amazing-teams.svg';
import understand_employees from './resources/understand_employees.svg'
import technicalTalent from './resources/technical-talent.svg'
import voting from './resources/voting.svg'
import reach_out from './resources/reach-out.svg'
import multi_discipline from './resources/multi-discipline.svg'
function UnderstandEmployees(){
    return(
        <>
        <div className="body-understand-employees">
        <div className="div-40 div-num  div-mob" style={{"color":"#5C86A7","vertical-align":"middle", margin: '0 auto'}}>
            <h3>EMPLOYEE ENGAGEMENT</h3>

            <div style={{margin:'auto','text-align':'center'}}><p className="heading-text-h2" style={{color:'#000'}}>Understand your employees</p></div><br/><br/>
            <img src={understand_employees} className="background-bg" style={{"text-align":"center"}}/>

            <div tyle={{'text-align':'center'}}><p className="para-text-h3" style={{color:'#000', 'float':'left'}}>Worried about growing skills gaps in your internal team? Understand your employees better with detailed insights about their performances. <br/>We’ll provide you with the most individually efficient metrics & operating details of your team. Explore the underlying nuances of your software development team & make rapid improvements.  
</p></div>
            </div>
            <div className="div-60">
            <div className="truthboard-nav" style={{"grid-template-columns": "auto auto auto","width":"100%"}}>
        <p className="dashboard-ul-truth highlight">Connect</p>
        <p className="dashboard-ul-truth">Prep</p>
        <p className="dashboard-ul-truth">Automate</p>


</div>
                <img src={understand_employees} className="display-overlay" style={{"width":"100%","top":"0px","left":"0%"}}/>
            </div>
          
           </div>
           <div className="body-virtualization" style={{"height":"auto","top":"0px"}}>
           <div className="div-50">
                <img src={technicalTalent} className="display-overlay-bg" />
            </div>
            <div className="div-50" style={{top:"0px"}}>
                <h2 style={{"padding-top":"0%"}}>Meet top technical talent ready for a change.</h2>
                <img src={technicalTalent} className="background-bg"/>

                <div>
                    <div style={{"display":"flex","flex-direction":"row"}}> <img src={voting}/><h1 style={{"margin":"20px"}}> We do the voting for you</h1></div>
                    <p>Our recruiting experts will review each candidate before recommending them to you.</p>
                    
                    </div>
                    <div>
                    <div style={{"display":"flex","flex-direction":"row"}}> <img src={reach_out}/><h1 style={{"margin":"20px"}}>Reach out & actually hear back</h1></div>
                    <p>We’ll help you reach out & receive swift responses from some of the most highly-rated technicians in the industry.</p>
                    
                    </div>
                    <div>
                    <div style={{"display":"flex","flex-direction":"row"}}> <img src={multi_discipline}/><h1 style={{"margin":"20px"}}> Multiple disciplines from all over the world</h1></div>
                    <p>Give your enterprise a competitive advantage by teaming up with experts from different sectors of software development. </p>
                    
                    </div>
            </div>


        </div>
           </>
    );


}
export default UnderstandEmployees;